import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

import bodyIcon from "../assets/img/body-icon-blue.svg";
import footIcon from "../assets/img/foot-icon.svg";

/**
 * Modal component
 *
 * @param {object} props
 */
const Modal = ({ isModalOpen, setIsModalOpen }) => {
  if (isModalOpen) {
    return (
      <ul
        className="nav__dropdown gs-u-align-center gs-u-p"
        onClick={() => setIsModalOpen(false)}
      >
        <button
          className="nav__dropdown_button button"
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faTimes} className="" />
        </button>
        <div className="nav__content gs-u-p+">
          <h3 className="gs-u-mb">Book in with:</h3>
          <li className="gs-u-display-inline-block gel-1/2">
            <NavLink to="/foot-clinic/booking" className="gs-u-display-block">
              <img src={footIcon} alt="The Foot Clinic" className="gel-3/4" />
              <p>
                <b>Foot Clinic</b>
              </p>
            </NavLink>
          </li>
          <li className="gs-u-display-inline-block gel-1/2">
            <NavLink to="/injury-clinic/booking" className="gs-u-display-block">
              <img src={bodyIcon} alt="The Injury Clinic" className="gel-3/4" />
              <p>
                <b>Injury Clinic</b>
              </p>
            </NavLink>
          </li>
        </div>
      </ul>
    );
  }

  return null;
};

export default Modal;
