import React from "react";
import Nav from "./nav";

/**
 * Header component
 *
 * @param {object} props
 */
const Header = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <header className="header gs-u-pv+ gs-o-media-island">
      <div className="gel-wrap gs-o-media-island">
        <Nav isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </div>
    </header>
  );
};

export default Header;
