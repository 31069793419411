import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import Spinner from "react-spinkit";
import { pageView } from "../utils/analytics";

const isFootClinicPage = (pathname) => pathname.indexOf("foot-clinic") >= 0;
const isInjuryClinicPage = (pathname) => pathname.indexOf("injury-clinic") >= 0;

const getDocumentSubTitle = (pathname) => {
  if (isInjuryClinicPage(pathname)) {
    return "The Moffat Injury Clinic";
  }

  return "The Moffat Foot Clinic";
};

const getBookingsSection = (pathname, onLoadCallback) => {
  const iframeTitle = getDocumentSubTitle(pathname);
  const iframeId = isFootClinicPage(pathname) ? "21541601" : "11803220";
  const businessId = isFootClinicPage(pathname) ? "55071" : "84955";
  const iframeSrc = isFootClinicPage(pathname)
    ? "https://the-moffat-clinic.cliniko.com/bookings"
    : "https://the-moffat-injury-clinic.uk2.cliniko.com/bookings";
  return (
    <iframe
      title={iframeTitle}
      id={`cliniko-${iframeId}`}
      src={iframeSrc}
      frameBorder="0"
      scrolling="auto"
      width="100%"
      style={{ pointerEvents: "auto", minHeight: "600px" }}
      onLoad={onLoadCallback}
    />
  );
};

const getLoader = () => {
  return (
    <div className="gs-u-align-center spacing-slice--large spacing-slice--bottom">
      <Spinner
        className="loading text-center"
        name="three-bounce"
        color="#212e50"
        fadeIn="none"
      />
    </div>
  );
};

const getHelmetTags = (pathname) => {
  if (isFootClinicPage(pathname)) {
    return (
      <Helmet>
        <title>
          Book your appointment - The Moffat Foot Clinic - The Moffat Clinic
        </title>
        <meta
          name="description"
          content="At The Moffat Foot Clinic, we use a cloud based appointment system called Cliniko. Use this page to book your appointment with us."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    );
  }

  if (isInjuryClinicPage(pathname)) {
    return (
      <Helmet>
        <title>
          Book your appointment - The Moffat Injury Clinic - The Moffat Clinic
        </title>
        <meta
          name="description"
          content="At The Moffat Injury Clinic, we use a cloud based appointment system called Cliniko. Use this page to book your appointment with us."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    );
  }
};

/**
 * Booking component
 *
 * @param {object} props
 */
const Booking = ({ location }) => {
  const { pathname } = location;
  const [loading, setLoading] = useState(true);

  useEffect(() => pageView());

  return (
    <React.Fragment>
      {getHelmetTags(pathname)}
      <section className="gel-wrap">
        <ul className="gel-layout spacing-slice--large">
          <li className="gel-layout__item gel-1/2@l">
            <h2 className="gs-u-mb++">Book your appointment</h2>
            <p className="text--large gs-u-mb">
              At <b>{getDocumentSubTitle(pathname)}</b>, we use a cloud based
              appointment system called Cliniko.
            </p>
            <p className="text--large gs-u-mb">
              Please use our form down below to book your appointment with us.
            </p>
          </li>
          <li className="gel-layout__item gel-1/2@l gs-u-align-center gs-o-media-island gs-u-display-none gs-u-display-inline-block@l">
            <figure className="gel-8/12 gel-6/12@l gs-u-display-inline-block centered@l">
              <svg viewBox="0 0 243 58.9" preserveAspectRatio="xMidYMin meet">
                <path
                  fill="#00A9CE"
                  d="M205.1,24.2c-0.4-0.4-0.5-1-0.1-1.4s1-0.5,1.4-0.1c1.8,1.5,4.1,1.4,5.6,0c0.4-0.4,1-0.3,1.4,0.1
                    s0.3,1-0.1,1.4C211.1,26.2,207.6,26.4,205.1,24.2z"
                />
                <path
                  fill="#6AD1E3"
                  d="M192.7,4.8c0.3-0.3,0.1-0.6,0.8-1.1c1.9-1.6,8.5-1.4,9.1,0.9c0.2,0.7,0.4,0.3,3.7,1.1c0.2,0,0.9,0.3,0.9,0
                    c0-0.2-0.7-1.2-0.9-1.4c-4.3-5.4-12.6-5.7-17.2-0.5c-0.2,0.2-1.6,1.7-1.1,2c0.3,0.2,0.5-0.1,4.1-0.7C192.4,5,192.6,5,192.7,4.8z"
                />
                <path
                  fill="#00A9CE"
                  d="M197.7,9.1c1.4,0,3.1-3,3-3.6c-0.2-1.7-5.8-1.7-5.9,0C194.8,6,196.4,9.1,197.7,9.1z"
                />
                <path
                  fill="#007FA3"
                  d="M154.1,27.2c-8.3,0-15,5.9-15,16.1c0,20.9,29.6,20.9,29.6-0.6C168.7,33.5,162.8,27.2,154.1,27.2z
                    M153.9,54.7c-12.1,0-12.3-23.4,0.1-23.4C166.2,31.3,166,54.7,153.9,54.7z"
                />
                <path
                  fill="#007FA3"
                  d="M35.4,49.9l-1.3-1.1c-0.7-0.4-1.5-0.3-2.2,0.2c-3.2,2.5-6,4.2-10.8,4.2c-8.6,0-15.6-7.2-15.6-16.1
                    S12.5,21,21.1,21c3.7,0,7.7,1.6,10.7,4.2c0.7,0.7,1.5,0.8,2.1,0.5l1.5-1.5c0.7-0.7,0.8-1.8-0.1-2.7c-4.4-3.7-8.8-5.4-14.1-5.4
                    C9.5,16.1,0,25.6,0,37.2s9.5,21,21.1,21c5.5,0,10.4-1.9,14.2-5.7C36.1,51.8,36.1,50.4,35.4,49.9z"
                />
                <path
                  fill="#007FA3"
                  d="M45.8,13.8h-1.4c-1.1,0-2.1,0.9-2.1,2.1v40.3c0,1.1,0.9,2.1,2.1,2.1h1.4c1.1,0,2.1-0.9,2.1-2.1V15.8
                    C47.8,14.7,46.9,13.8,45.8,13.8z"
                />
                <path
                  fill="#007FA3"
                  d="M58.4,27.9H57c-1.1,0-2.1,0.9-2.1,2.1v26.1c0,1.1,0.9,2.1,2.1,2.1h1.4c1.1,0,2.1-0.9,2.1-2.1V30
                    C60.5,28.8,59.5,27.9,58.4,27.9z"
                />
                <path
                  fill="#007FA3"
                  d="M103.5,27.9h-1.4c-1.1,0-2.1,0.9-2.1,2.1v26.1c0,1.1,0.9,2.1,2.1,2.1h1.4c1.1,0,2.1-0.9,2.1-2.1V30
                    C105.5,28.8,104.6,27.9,103.5,27.9z"
                />
                <path
                  fill="#007FA3"
                  d="M124.7,40.2c0.4-0.4,8.3-8.9,8.4-8.9c1.2-1.4,0.2-3.5-1.6-3.4c-0.9,0-1.6-0.1-2.3,0.6L118,40.1
                    c0-33.5,0-21.7,0-24.3c0-1.1-0.9-2.1-2.1-2.1h-1.4c-1.1,0-2.1,0.9-2.1,2.1v40.3c0,1.1,0.9,2.1,2.1,2.1h1.4c1.2,0,2.1-1,2.1-2.1
                    c0-1,0,3.4,0-9.3c0,0,2.3-2.7,3-3.3c8.8,2,10.2,9,10.5,12.6c-0.1,0.8,0.7,2.1,2.1,2.1h1c1.2,0,2.1-1,2.1-2.1
                    C136.4,51.9,135.1,42.8,124.7,40.2z"
                />
                <path
                  fill="#007FA3"
                  d="M81,27.2c-3.9,0-7.1,2.1-8.6,4.9V30c0-1.3-0.9-2.2-2-2.2h-0.9c-1.1,0-2.1,0.9-2.1,2.1c0,2.6,0-9.6,0,25.9
                    c-0.1,1.2,0.8,2.3,2,2.3h1.4c1.1,0,2.1-0.9,2.1-2.1c0-1.4,0,5.3,0-14.7c0-6.9,4.2-9.6,7.8-9.6c5.2,0,7,4.1,7,9
                    c0,20.9,0,13.9,0,15.4c0,1.2,0.9,2.1,2.1,2.1h1.4c1.1,0,2.1-0.9,2.1-2.1v-16C93.2,29.7,86.4,27.2,81,27.2z"
                />
                <path
                  fill="#007FA3"
                  d="M57.7,16c-4.6,0-4.6,6.8-0.1,6.8C62.3,22.8,62.2,16,57.7,16z"
                />
                <path
                  fill="#007FA3"
                  d="M102.8,16c-4.6,0-4.5,6.8-0.1,6.8C107.4,22.8,107.2,16,102.8,16z"
                />
                <path fill="#06ACD6" d="M187,56L187,56L187,56L187,56z" />
                <path
                  fill="#6AD1E3"
                  d="M208.5,8.3c-0.1-0.4-0.2-0.6-0.6-0.7c-0.4-0.2-2.1-0.7-4.5-0.9c-0.6-0.1-1.2-0.3-1.5,0.3
                    c-0.4,0.6-0.7,1.2-1.2,1.7c-2.3,2.8-4,3-6.6-1.3c-0.8-1.3-2-0.8-3.2-0.6c-0.8,0.2-3.3,0.7-3.7,0.9c-0.4,0.2-0.5,0.9-0.6,1.7
                    c-0.9,5.1,1.8,10,6.3,12.1c0.3,0.1,0.7,0.3,0.6,0.6c-0.1,0.6-1.2,0.9-1.6,1.2c-3.8,1.9-7.4,5-10.3,9.1c-4.8,7-6.4,15.1-6.8,23.6
                    c-0.1,1.2,0.9,2.1,2,2.1h4.5c1,0,1.8-0.7,2-1.6c0.5-4.7,1.5-10.2,3.7-14.4c3.3-6.7,8.4-11.4,13.2-13.7c0.9-0.4,2-0.8,1.1-1.9
                    c-5.2-6.5-1.1-15.2,6.2-16.3C208.7,10.1,209,10,208.5,8.3z"
                />
                <path
                  fill="#05C3DE"
                  d="M228.6,27c-0.3-0.2-0.9-0.5-0.9-0.9s0.5-0.5,0.8-0.7c8.7-5.4,4.8-18.7-5.3-18.7c-2.7,0-5.3,1.1-6.9,2.7
                    c-0.5,0.5-2.1,1.9-1.4,2.7c0.3,0.3,0.7,0.6,1,0.8c1.9,1.8,3.1,4.4,3.1,7.2c0,2.1-0.6,3.8-1.7,5.6c-0.3,0.6-1,1.1-0.3,1.7
                    c1.5,1.2,7.6,4.2,12.4,11.8c2.4,3.9,4.4,8.5,5.3,17c0,1,0.9,2,2,2h4.3c1.1,0,2-0.8,2-1.9C242.7,45,237.8,33.4,228.6,27z"
                />
                <path fill="#06ACD6" d="M209.4,53L209.4,53L209.4,53z" />
                <path
                  fill="#00A9CE"
                  d="M231.7,56.2c-0.3-2.9-0.8-5.4-1.7-8.3c-2.1-6.7-6.3-13.5-12.6-16.4c-1.3-0.6-5.1-1.9-6-0.9
                  c-0.5,0.5-0.3,1.4,0.1,5.1c1.2,11.9,1.3,13.1,1.3,13.7c0,1.1-2.2,3.1-2.6,3.5c-0.3,0.3-0.9,0.3-1.3,0c-0.3-0.4-2.5-2.4-2.5-3.4
                  c0-2.4,1.7-17.5,1.7-18c0-1.9-2.2-1.1-3.2-0.9c-7.9,1.8-13.2,9.6-15.7,17.4c-1,3.1-1.6,6.9-1.7,8.1c-0.1,1.1,0.9,2.1,2,2.1h40
                  C230.8,58.2,231.7,57.2,231.7,56.2z"
                />
              </svg>
            </figure>
          </li>
        </ul>
      </section>
      <section className="gel-wrap spacing-slice--large">
        {loading ? getLoader() : null}
        {getBookingsSection(pathname, () => setLoading(false))}
      </section>
    </React.Fragment>
  );
};

Booking.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Booking);
