import React from "react";
import { withRouter } from "react-router-dom";

const isFootClinicPage = (pathname) => pathname.indexOf("foot-clinic") >= 0;
const isInjuryClinicPage = (pathname) => pathname.indexOf("injury-clinic") >= 0;
const isClinicPage = (pathname) =>
  isFootClinicPage(pathname) || isInjuryClinicPage(pathname);

const getClinicName = (pathname) => {
  if (isFootClinicPage(pathname)) {
    return "The Moffat Foot Clinic";
  }

  if (isInjuryClinicPage(pathname)) {
    return "The Moffat Injury Clinic";
  }

  return "The Moffat Clinic";
}

/**
 * Footer component
 *
 * @param {object} props
 */
const Footer = ({ location }) => {
  const { pathname } = location;
  return (
    <footer
      className={`footer gs-u-pv++ ${
        isClinicPage(pathname) ? "footer--white" : ""
      }`}
    >
      <div className="gel-wrap gs-u-clearfix">
        <div className="gel-1/2@l gs-u-float-left@l">
          <ul className="footer__section">
            <li className="footer__section_item gs-u-clearfix gs-u-display-block gs-u-mb+">
              <div className="gs-o-media__body gs-u-display-inline-block gs-u-ml-">
                <span className="footer__addr_1 gs-u-display-block gs-u-mb-">
                  1 Holm Street
                </span>
                <span className="footer__addr_2 gs-u-display-block gs-u-mb-">
                  Moffat
                </span>
                <span>DG10 9EB</span>
              </div>
            </li>
            <li className="footer__section_item gs-u-clearfix gs-u-display-block gs-u-mb+">
              <span className="gs-u-float-left@l gs-u-align-top gs-u-mr+">
                <svg
                  version="1.1"
                  xmlns="&ns_svg;"
                  width="40.248"
                  height="40.252"
                  viewBox="0 0 40.248 40.252"
                  overflow="visible"
                  enableBackground="new 0 0 40.248 40.252"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M40.249,20.123c0,11.11-9.008,20.128-20.124,20.128C9.011,40.251,0,31.233,0,20.123
                        C0,9.011,9.011,0,20.125,0C31.241,0,40.249,9.011,40.249,20.123z"/>
                    <g>
                      <g>
                        <path
                          fill="#1C3664"
                          d="M19.338,13.852c-0.465,0-0.958,0-1.467,0.015c-0.513,0.008-1.023,0.022-1.536,0.044
                            c-0.512,0.03-1.005,0.06-1.481,0.097c-0.475,0.029-0.901,0.073-1.277,0.125c0.088-0.324,0.133-0.611,0.133-0.862
                            c0-0.199-0.045-0.472-0.133-0.833c1.186,0.037,2.343,0.074,3.473,0.104c1.132,0.029,2.289,0.044,3.475,0.044
                            c1.187,0,2.344-0.015,3.477-0.044c1.129-0.029,2.285-0.066,3.473-0.104c-0.088,0.361-0.133,0.648-0.133,0.862
                            c0,0.229,0.045,0.509,0.133,0.833c-0.377-0.052-0.803-0.096-1.277-0.125c-0.477-0.037-0.971-0.066-1.482-0.097
                            c-0.512-0.021-1.023-0.036-1.535-0.044c-0.51-0.015-1.002-0.015-1.468-0.015c-0.052,1.851-0.081,3.708-0.081,5.57v4.626
                            c0,1.188,0.022,2.359,0.066,3.501c0.044,1.149,0.096,2.284,0.147,3.396c-0.413-0.044-0.854-0.059-1.319-0.059
                            c-0.464,0-0.906,0.015-1.318,0.059c0.055-1.112,0.106-2.247,0.15-3.396c0.049-1.142,0.07-2.313,0.07-3.501v-4.626
                            C19.426,17.56,19.396,15.703,19.338,13.852z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <a
                href="tel:01683 222796"
                className="footer__phone gs-u-display-inline-block gs-u-pv footer__text"
              >
                <b>01683 222796</b>
              </a>
            </li>
            <li className="footer__section_item gs-u-clearfix gs-u-display-block gs-u-mb++">
              <span className="gs-u-float-left@l gs-u-align-top gs-u-mr+">
                <svg
                  version="1.1"
                  xmlns="&ns_svg;"
                  width="40.248"
                  height="40.252"
                  viewBox="0 0 40.248 40.252"
                  overflow="visible"
                  enableBackground="new 0 0 40.248 40.252"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M40.249,20.123c0,11.11-9.008,20.128-20.124,20.128C9.011,40.251,0,31.233,0,20.123
                        C0,9.011,9.011,0,20.125,0C31.241,0,40.249,9.011,40.249,20.123z"/>
                    <g>
                      <g>
                        <path
                          fill="#1C3664"
                          d="M15.63,15.885c-0.039-1.149-0.088-2.299-0.14-3.448c0.803,0.037,1.606,0.074,2.401,0.104
                            c0.796,0.029,1.6,0.044,2.407,0.044c0.806,0,1.605-0.015,2.397-0.044c0.789-0.029,1.588-0.066,2.395-0.104
                            c-0.051,0.266-0.08,0.538-0.08,0.811c0,0.266,0.029,0.538,0.08,0.811c-0.574-0.036-1.098-0.073-1.576-0.103
                            c-0.473-0.037-0.977-0.074-1.507-0.11c-0.527-0.03-1.109-0.06-1.735-0.089c-0.627-0.022-1.386-0.037-2.263-0.037
                            c-0.015,0.575-0.036,1.12-0.066,1.636c-0.021,0.524-0.036,1.114-0.036,1.777c0,0.698,0.007,1.376,0.015,2.039
                            c0.007,0.663,0.015,1.165,0.015,1.512c0.788,0,1.5-0.016,2.129-0.029c0.627-0.016,1.201-0.045,1.722-0.068
                            c0.519-0.029,0.997-0.049,1.44-0.08c0.441-0.029,0.854-0.059,1.252-0.096c-0.055,0.273-0.082,0.544-0.082,0.833
                            c0,0.271,0.027,0.545,0.082,0.81c-0.467-0.053-1.01-0.098-1.629-0.133c-0.619-0.039-1.237-0.059-1.856-0.068
                            c-0.619-0.006-1.204-0.019-1.754-0.029c-0.545-0.006-0.979-0.016-1.304-0.016c0,0.162-0.008,0.339-0.015,0.531
                            c-0.008,0.186-0.015,0.422-0.015,0.707v1.055v1.615c0,1.023,0.007,1.863,0.015,2.525c0.007,0.664,0.021,1.172,0.044,1.512
                            c0.594,0,1.223,0,1.886,0c0.679,0,1.349-0.016,2.005-0.045c0.655-0.021,1.275-0.059,1.857-0.104
                            c0.582-0.043,1.09-0.109,1.521-0.197c-0.033,0.125-0.057,0.264-0.066,0.42c-0.012,0.154-0.014,0.293-0.014,0.42
                            c0,0.287,0.023,0.516,0.08,0.699c-0.807-0.045-1.611-0.059-2.41-0.059c-0.8,0-1.61,0-2.436,0s-1.643,0-2.449,0
                            c-0.808,0-1.625,0.014-2.45,0.059c0.052-1.15,0.101-2.299,0.14-3.434c0.045-1.143,0.066-2.307,0.066-3.494v-4.631
                            C15.696,18.199,15.675,17.035,15.63,15.885z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <a
                href="mailto:themoffatclinic@gmail.com"
                className="gs-u-display-inline-block gs-u-pv footer__text"
              >
                <b className="footer__email">themoffatclinic@gmail.com</b>
              </a>
            </li>
          </ul>
        </div>
        <div className="gs-u-float-right@l gel-1/2@l gel-1/4@xl">
          <h3 className="gs-u-mb">{getClinicName(pathname)}</h3>
          <p className="gs-u-mb+">
            Helping you live your life to the best of your ability.
          </p>
          <ul className="footer__socials">
            <li className="gs-u-display-inline gs-u-mr+">
              <a href="https://www.facebook.com/The-Moffat-Clinic-106650907414195/">
                <svg
                  xmlns="&ns_svg;"
                  width="40.251"
                  height="40.635"
                  viewBox="0 0 40.251 40.635"
                  overflow="visible"
                  enableBackground="new 0 0 40.251 40.635"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M40.25,20.123c0,11.112-9.011,20.129-20.122,20.129C9.011,40.252,0,31.235,0,20.123
                    C0,9.011,9.011,0,20.129,0C31.24,0,40.25,9.011,40.25,20.123z"/>
                    <path
                      fill="#1C3664"
                      d="M29.593,26.268h-5.375l-0.03,14.338l-6.508,0.029V26.268h-5.407v-6.271h5.407v-5.29
                    c0,0,0.124-3.397,2.191-5.475c2.066-2.07,5.088-2.122,5.088-2.122s1.267-0.074,2.364-0.029c1.091,0.037,3.367,0.235,3.367,0.235
                    l-0.059,5.585L26.73,12.96c0,0-1.123-0.059-1.805,0.619c-0.651,0.648-0.737,1.717-0.737,1.717v4.701h6.263L29.593,26.268z"/>
                  </g>
                </svg>
              </a>
            </li>
            <li className="gs-u-display-inline">
              <a href="https://instagram.com/themoffatfootclinic">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="&ns_svg;"
                  width="40.25"
                  height="40.252"
                  viewBox="0 0 40.25 40.252"
                  overflow="visible"
                  enableBackground="new 0 0 40.25 40.252"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M40.25,20.122c0,11.113-9.01,20.13-20.121,20.13C9.01,40.252,0,31.235,0,20.122C0,9.011,9.01,0,20.13,0
                        C31.241,0,40.25,9.011,40.25,20.122z"/>
                    <path
                      fill="none"
                      stroke="#1C3664"
                      strokeWidth="2.8293"
                      d="M33.247,14.419v12.386c0,0,0.174,2.057-1.617,4.076
                        c-1.66,1.871-5.443,1.826-5.443,1.826H14.595c0,0-3.684,0.088-5.37-1.672c-2.038-2.115-1.729-4.561-1.729-4.561V12.901
                        c0,0-0.019-2.174,1.817-3.816c1.729-1.555,4.601-1.496,4.601-1.496h12.557c0,0,3.414-0.221,5.229,1.827
                        C33.491,11.428,33.247,14.419,33.247,14.419z"
                    />
                    <path
                      fill="#1C3664"
                      d="M29.821,12.695c0,0.921-0.746,1.665-1.664,1.665c-0.922,0-1.674-0.744-1.674-1.665s0.752-1.665,1.674-1.665
		                    C29.075,11.03,29.821,11.774,29.821,12.695z"
                    />
                    <path
                      fill="none"
                      stroke="#1C3664"
                      strokeWidth="2.8293"
                      d="M26.852,19.851c0,3.228-2.822,5.851-6.299,5.851
		                  c-3.482,0-6.297-2.623-6.297-5.851c0-3.235,2.814-5.852,6.297-5.852C24.03,13.999,26.852,16.615,26.852,19.851z"/>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
          <p className="gs-u-mt++">
            &copy; {getClinicName(pathname)} {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
