import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { trackEvent } from "../utils/analytics";
import logo from "../assets/img/logo.svg";
import footLogo from "../assets/img/foot-icon-full.svg";
import injuryLogo from "../assets/img/body-icon-full.svg";

const isFootClinicPage = (pathname) => pathname.indexOf("foot-clinic") >= 0;
const isInjuryClinicPage = (pathname) => pathname.indexOf("injury-clinic") >= 0;

const getLogo = (pathname) => {
  if (isFootClinicPage(pathname)) {
    return footLogo;
  }

  if (isInjuryClinicPage(pathname)) {
    return injuryLogo;
  }

  return logo;
};

const getLogoClasses = (pathname) => {
  if (isFootClinicPage(pathname)) {
    return "nav__logo--foot";
  }

  if (isInjuryClinicPage(pathname)) {
    return "nav__logo--injury";
  }

  return "gs-u-mt+";
};

const getBookingsLink = (pathname) => {
  if (isInjuryClinicPage(pathname)) {
    return "/injury-clinic/booking";
  }

  return "/foot-clinic/booking";
};

/**
 * Nav component
 *
 * @param {object} props
 */
const Nav = ({ location, setIsModalOpen }) => {
  const { pathname } = location;
  return (
    <React.Fragment>
      <div className="nav gs-o-media-island gel-1/1">
        <ul className="nav__list gs-u-display-inline gs-u-float-left gs-u-mt+">
          <li className="nav__item gs-u-display-inline gs-u-mr+">
            <a href="tel:01683 222796" className="gs-u-pv">
              <FontAwesomeIcon icon={faPhone} className="gs-u-mr" />
              <span>01683 222796</span>
            </a>
          </li>
        </ul>
        <NavLink
          className={`nav__logo gs-u-display-inline-block ${getLogoClasses(
            pathname
          )}`}
          to="/"
        >
          <img src={getLogo(pathname)} alt="The Moffat Clinic" />
        </NavLink>
        <button
          className="button nav__book_now nav__item gs-u-p+ gs-u-float-right"
          onClick={() => {
            trackEvent({
              action: "nav-book-now-button",
              label: pathname,
            });
            setIsModalOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="gs-u-mr@l" />
          <span>Book now</span>
        </button>
      </div>
    </React.Fragment>
  );
};

Nav.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Nav);
