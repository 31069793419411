import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Reviews from "../components/reviews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { pageView } from "../utils/analytics";

import footProffIcon from "../assets/img/foot-icon-college.jpg";
import hcpcIcon from "../assets/img/hcpc.jpg";
import anneIcon from "../assets/img/anne.jpg";
import biomechanics from "../assets/img/biomechanics-assessment.jpg";
import diabetic from "../assets/img/diabetic-foot-assessment.jpg";
import gait from "../assets/img/gait-analysis.jpg";
import general from "../assets/img/general-podiatry.jpg";
import orthotic from "../assets/img/orthotic.jpg";
import plantar from "../assets/img/plantar-fascitiis.jpg";
import pressurePlate from "../assets/img/pressure-plate-analysis.jpg";
import ultrasound from "../assets/img/ultrasound.jpg";
import collage from "../assets/img/foot-clinic-collage.png";
import waxBath from "../assets/img/wax-bath.png";
import fungalNail from "../assets/img/fungal-nail-test.jpeg";

/**
 * FootClinic component
 *
 * @param {object} props
 */
const FootClinic = () => {
  useEffect(() => pageView());

  return (
    <React.Fragment>
      <Helmet>
        <title>The Moffat Foot Clinic - The Moffat Clinic</title>
        <meta
          name="description"
          content="Anne Bloomer. B.Sc.; D.Pod.M.; M.C.Pod. Anne has over 30 years experience in both the NHS and private practice. She has a particular interest in diabetes having been a diabetes specialist in the NHS for over 10 years."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className="gel-wrap testimonies gs-u-align-center spacing-slice--large">
        <div className="spacing-slice--large spacing-slice--large--bottom">
          <h2 className="gs-u-mb++">Our Services</h2>
          <ul className="gel-wrap gel-layout gel-layout--equal">
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${general})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">General Podiatry</b>
                Treatment of corns, callous, ingrown toenails & a toenail
                cutting service.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${plantar})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Plantar Fascitiis Treatment{" "}
                </b>
                Plantar Fasciitis is severe pain typically found on the heel
                and/or arch area. Treatment can include heat therapy/ultrasound,
                strapping and targeted exercises.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${diabetic})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Diabetic Foot Assessment
                </b>
                Annual review of circulation, sensation & foot
                structure/function to determine possible effects of diabetes on
                your feet.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${orthotic})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Orthotics </b>{" "}
                Insoles manufactured to reduce pressure on painful areas, adjust
                gait or provide cushioning to your feet. Each individually
                prescribed to your needs.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${ultrasound})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Ultrasound therapy
                </b>
                This is a painless high frequency treatment used to treat deep
                tissue injuries and so speed your healing process.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${biomechanics})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Biomechanical assessment
                </b>
                This is a way of diagnosing any imbalance or weakness in your
                feet & leg joints.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${gait})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Video Gait Analysis
                </b>
                Video recording of your walking/running gait to determine
                abnormalities and help adjust these. This includes detailed
                printed analysis & advice.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${pressurePlate})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Pressure Plate Analysis
                </b>
                A device used to produce a 3D image detailing pressures exerted
                by your foot when walking and standing.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${waxBath})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Paraffin Wax Bath Therapy
                </b>
                Paraffin wax is an effective way of applying heat to joints and
                connective tissues. It is mainly used on hands and feet.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image "
                style={{ backgroundImage: `url(${fungalNail})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Dermatophyte Fungal Infection Testing
                </b>
                The test works by a process known as immune-chromotography,
                which is also found in a pregnancy test. It contains an antibody
                that specifically reacts with a chemical found in the
                dermatophyte infection which causes most fungal nail infections.
              </p>
            </li>
          </ul>
          <div className="gs-u-mt++">
            <NavLink
              className="button gs-u-p+ nav__item"
              to="/foot-clinic/booking"
            >
              <FontAwesomeIcon icon={faCalendarAlt} className="gs-u-mr@l" />{" "}
              Book now
            </NavLink>
          </div>
        </div>
      </section>
      <section className="gel-wrap gs-u-align-center spacing-slice--large spacing-slice--large--bottom">
        <figure className="gs-u-display-inline-block gs-u-p+ gs-u-pt-alt+ gel-7/12 gs-u-mr++@m gel-3/12@m gel-3/12@l gel-3/12@xl gel-2/12@xxl gs-u-align-middle">
          <img src={hcpcIcon} alt="" />
        </figure>
        <figure className="gs-u-display-inline-block gs-u-p+ gs-u-pt-alt+ gel-8/12 gs-u-ml++@m gel-5/12@m gel-4/12@l gel-4/12@xl gel-3/12@xxl gs-u-align-middle">
          <img src={footProffIcon} alt="" />
        </figure>
      </section>
      <section className="section--dark spacing-slice--large spacing-slice--large--bottom">
        <div className="gel-wrap padding-slice--large padding-slice--large--bottom">
          <ul className="gel-layout">
            <li className="gel-layout__item gel-1/2@l gs-u-align-center gel-1/2@l gs-u-mb++ gs-u-mb0@l">
              <figure className="gel-6/12 gs-u-display-inline-block">
                <img className="image--rounded" src={anneIcon} alt="" />
              </figure>
            </li>
            <li className="gel-layout__item gel-1/2@l gs-u-mt++@xxl">
              <h2 className="gs-u-mb++">About The Moffat Foot Clinic</h2>
              <p className="text--large gs-u-mb">
                <b>Anne Bloomer. B.Sc.; D.Pod.M.; M.C.Pod</b>
              </p>
              <p className="text--large gs-u-mb++">
                Anne has over 30 years experience as a podiatrist in both the
                NHS and private practice. During her time in the health service
                she spent almost 10 years specialising in diabetes and still has
                a keen interest in this condition. She also worked as a clinical
                tutor to Podiatry undergraduate students at the Queen Elizabeth
                Hospital in Glasgow and finally in a management post in NHS
                Lanarkshire.
              </p>
              <p className="text--large gs-u-mb++">
                On leaving the NHS, Anne opened her first clinic in Moffat in
                2016. Both she and Paula always had a close working
                relationship, often treating the same patient giving quicker
                more effective results. They decided to join forces and opened
                The Moffat Clinic in November 2019. The clinic has been very
                well received and both therapists look forward to getting you
                back to better health and complete fitness - from head to toe.
              </p>
              <p className="text--large gs-u-mb++">
                Both our Podiatrist and Therapist have an excellent working
                relationship with GP practices & should further specialist
                assessment be required this can be facilitated.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="gel-wrap testimonies gs-u-align-center spacing-slice--large--bottom">
        <h2 className="gs-u-mb++">Reviews</h2>
        <Reviews
          reviews={[
            {
              text: "After seeing several different podiatrists and finding little relief, I had an appointment with Anne. Thoroughly impressive and professional, Anne provided some excellent treatment and advice that no-one had ever done before. Thank you Anne.",
            },
            {
              text: "After suffering for weeks with shin splints I booked an appointment with Anne. One gait analysis later, a thorough assessment and some helpful advice, I'm back running and no longer experiencing any pain. Glad to be back out running.",
            },
            {
              text: "After years of pain and many podiatrists later I found the Moffat Foot Clinic. One treatment here and I can't believe the relief.",
            },
            {
              text: "While on holiday recently in Moffat, I visited the clinic. Best foot treatment I've ever had, thanks Anne! Wish I lived closer.....",
            },
          ]}
        />
      </section>
      <section className="gel-wrap gel-wrap-small testimonies gs-u-align-center">
        <img className="spacing-slice--large--bottom" src={collage} alt="" />
      </section>
    </React.Fragment>
  );
};

export default FootClinic;
