import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import Reviews from "../components/reviews";

import paulaIcon from "../assets/img/paula.jpeg";
import assessment from "../assets/img/assessment.jpeg";
import heatTherapy from "../assets/img/heat-therapy.jpeg";
import kinTaping from "../assets/img/kinesiology-taping.jpeg";
import manualManip from "../assets/img/manual-manipulation.jpeg";
import sportTaping from "../assets/img/sports-taping.jpeg";
import ultrasound from "../assets/img/ultrasound.jpeg";
import massage from "../assets/img/sports-massage.jpeg";
import rehab from "../assets/img/rehab.jpeg";
import specialisedCare from "../assets/img/specialised-care.jpeg";
import collage from "../assets/img/injury-clinic-collage.png";
import sto from "../assets/img/sto.jpeg";
import cupping from "../assets/img/cupping.png";
import iastm from "../assets/img/iastm.jpeg";
import { pageView } from "../utils/analytics";

/**
 * InjuryClinic component
 *
 * @param {object} props
 */
const InjuryClinic = () => {
  useEffect(() => pageView());

  return (
    <React.Fragment>
      <Helmet>
        <title>The Moffat Injury Clinic - The Moffat Clinic</title>
        <meta
          name="description"
          content="Paula Morrow - Higher National Diploma. Paula has been qualified for over 18 years and has continued her personal development over the years to include more specialised skills."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/*
      <section className="gel-wrap">
        <ul className="gel-layout spacing-slice--large">
          <li className="gel-layout__item gel-1/2@l">
            <h2 className="gs-u-mb++">About us</h2>
            <p className="text--large gs-u-mb">The Moffat Clinic provides complete head to toe care from our highly experienced team.</p>
            <p className="text--large gs-u-mb">Our services include Podiatry, Sports & Rehabilitation Therapy and <a className="link" href="https://www.facebook.com/liz.peacock.940">Massage Therapy</a>.</p>
            <p className="text--large gs-u-mb">Anne and Paula both had successful independent private practices in Moffat for a number of years before joining forces and opening The Moffat Clinic in November 2019. The clinic also has a <b>massage therapist</b>, Liz, who also offers treatment at her <a className="link" href="https://www.facebook.com/liz.peacock.940">clinic in Dumfries</a>.</p>
          </li>
          <li className="gel-layout__item gel-1/2@l gs-u-align-center gs-o-media-island gs-u-display-none gs-u-display-inline-block@l">
            <figure className="gel-8/12 gel-6/12@l gs-u-display-inline-block centered@l">
              <img src={moffatClinicIcon} alt="" />
            </figure>
          </li>
        </ul>
      </section>
      */}
      <section className="gel-wrap testimonies gs-u-align-center">
        <div className="spacing-slice--large spacing-slice--large--bottom">
          <h2 className="gs-u-mb++">Our Services</h2>
          <ul className="gel-wrap gel-layout gel-layout--equal">
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${assessment})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Assessment</b>All
                injuries will be assessed, diagnosed and evaluated on the day,
                with a full treatment plan discussed.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${heatTherapy})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Heat therapy</b>Heat
                increases blood flow to your injured muscle and aids the healing
                process.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${kinTaping})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Kinesiology Taping
                </b>
                A rehabilitative taping technique that is designed to facilitate
                the body’s natural healing process.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${manualManip})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Manual Manipulation/Therapy
                </b>
                Mobilisation of the joint & soft tissue by applying pressure in
                the muscle tissue.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${sportTaping})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Sports Taping</b>A
                more rigid style of strapping to restrict the motion of an
                injured joint.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${ultrasound})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Ultrasound</b>
                Painless high frequency sound waves used to treat deep tissue
                injuries and aid the healing process.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${massage})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Sports Massage</b>A
                form of deep tissue massage - designed to loosen tight muscles,
                lengthen short muscles, improve recovery after exercise and
                correct postural imbalances.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${rehab})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Rehabilitation</b>
                Personalised treatment plan tailored for you by your Therapist -
                this can include one to one Personal Training sessions.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${specialisedCare})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Specialised Treatment
                </b>
                Catered treatments for Pregnancy, Myalgic Encephalomyelitis
                (ME), Fibromyalgia, Arthritis, Scoliosis, Disc
                injury/degeneration & biomechanics.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${iastm})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">
                  Instrument Assisted Soft Tissue Mobilisation (IASTM)
                </b>
                Instrument Assisted Soft Tissue Mobilisation helps break down
                restrictions, reduce pain, and facilitate healing by addressing
                musculoskeletal problems at the cellular level.
              </p>
            </li>
            <li className="services__item gel-layout__item gs-u-align-top gs-u-p gs-u-ph gs-u-ph+@xl gs-u-mb+ gs-u-p+@xl gel-1/2@m gel-1/3@xl gel-1/4@xxl">
              <div
                className="services__image"
                style={{ backgroundImage: `url(${cupping})` }}
              ></div>
              <p className="services__item_content gs-u-p+ gs-u-display-inline-block">
                <b className="gs-u-display-block gs-u-mb+">Dry Cupping</b>
                An alternative therapy that involves suctioning the skin with
                glass, plastic, or silicone cups. It helps improve blood flow,
                boost immune function, remove toxins, and reduce pain.
              </p>
            </li>
          </ul>
          <div className="gs-u-mt++">
            <NavLink
              className="button gs-u-p+ nav__item"
              to="/injury-clinic/booking"
            >
              <FontAwesomeIcon icon={faCalendarAlt} className="gs-u-mr@l" />
              Book now
            </NavLink>
          </div>
        </div>
      </section>
      <section className="gel-wrap gs-u-align-center spacing-slice--large spacing-slice--large--bottom">
        <figure className="gs-u-display-inline-block gs-u-p+ gs-u-pt-alt+ gel-7/12 gel-3/12@m gel-3/12@l gel-3/12@xl gel-2/12@xxl gs-u-align-middle">
          <img src={sto} alt="" />
        </figure>
      </section>
      <section className="section--dark spacing-slice--large spacing-slice--large--bottom">
        <div className="gel-wrap padding-slice--large padding-slice--large--bottom">
          <ul className="gel-layout">
            <li className="gel-layout__item gel-1/2@l gs-u-align-center gel-1/2@l gs-u-mb++ gs-u-mb0@l">
              <figure className="gel-6/12 gs-u-display-inline-block">
                <img className="image--rounded" src={paulaIcon} alt="" />
              </figure>
            </li>
            <li className="gel-layout__item gel-1/2@l gs-u-mt+@xxl">
              <h2 className="gs-u-mb++">About The Moffat Injury Clinic</h2>
              <p className="text--large gs-u-mb">
                <b>Paula Morrow - Higher National Diploma</b>
              </p>
              <p className="text--large gs-u-mb++">
                Paula has been qualified for over 18 years & has continued her
                personal development gaining further specialised skills &
                treatments.
              </p>
              <p className="text--large gs-u-mb++">
                She also owns & runs The Moffat Injury Clinic Rehab Studio where
                she can tailor rehabilitation to patients individual needs.
                Within the rehab studio setting she is keen to maintain a
                personalised service to enable her patients to get back to and
                achieve their full potential again after injury.
              </p>
              <p className="text--large gs-u-mb++">
                Both our Podiatrist and Therapist have an excellent working
                relationship with GP practices & should further specialist
                assessment be required this can be facilitated.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="gel-wrap testimonies gs-u-align-center spacing-slice--large--bottom">
        <h2 className="gs-u-mb++">Reviews</h2>
        <ul className="reviews gel-layout gel-layout--equal">
          <li className={`reviews__item gel-layout__item gel-1/1 gs-u-p+`}>
            <div className="reviews__item-inner gs-u-p gs-u-p++@xl">
              <FontAwesomeIcon icon={faQuoteLeft} className="gs-u-mr+" />
              <p className="gs-u-display-inline">
                I've been seeing Paula on & off for a couple of years now for
                ongoing back issues. Paula is honestly brilliant at what she
                does, my back is so much better now and I now know how to manage
                things better, all through the advice Paula has given me. She
                has always made me feel very comfortable when treating me, and
                gives really good advice on different exercises or stretches I
                can do to reduce my back pain. She is incredibly knowledgable on
                a wide range of health & well being issues. As well as this, I
                have always been able to contact Paula in between appointments
                if I have needed any advice or reassurance. The Clinic at Moffat
                is also a lovely environment to be treated in, always very clean
                and private. Paula has always used a really nice person centred
                approach when treating myself, taking into account my job and
                lifestyle in order to provide me with the best advice, guidance
                and future treatment plans. I will continue to see Paula,
                despite having moved away from Moffat now, she is well worth the
                journey, and the positive impact sports massage has made on my
                life.
              </p>
            </div>
          </li>
        </ul>
        <Reviews
          reviews={[
            {
              text: "Paula provides an excellent very professional service for all kinds of musculoskeletal problems, whether caused by sport or not. I had struggled with a chronic injury for a couple of years and Paula rapidly diagnosed the problem and was able to help me. She uses a dual approach of both offering Sport Therapy treatment in the clinic, combined with setting you a programme of exercise to do either at home or in the gym in order to support the rehabilitation from injury and prevent recurrence. No hesitation in recommending her to others of all ages - Doctor David Hickson.",
            },
            {
              text: "Paula helped me with a shoulder injury that I have had for a long time and never knew what it was. I asked if she could take a look for me, within seconds she knew what the problem was. Paula also showed me how to tape it up and a couple of exercises that can help it - a couple of days I haven't had any problems. So highly recommend if you've got a problem you should take the time to go see her.",
            },
            {
              text: "Highly recommended, helping me get back from niggling injuries and back playing rugby, if it wasn't for the work Paula has done I'd probably be on the scrap heap full time.",
            },
          ]}
        />
      </section>
      <section className="gel-wrap gel-wrap-small testimonies gs-u-align-center">
        <img className="spacing-slice--large--bottom" src={collage} alt="" />
      </section>
    </React.Fragment>
  );
};

export default InjuryClinic;
