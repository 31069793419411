import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

/**
 * Reviews component
 *
 * @param {object} props
 */
const Reviews = ({ reviews }) => {
  const renderReview = ({ text }, index, itemClasses = '') => {
    return (
      <li
        className={`reviews__item gel-layout__item gel-1/1 gs-u-p+ ${itemClasses}`}
        key={`review-${index}`}
      >
        <div className="reviews__item-inner gs-u-p gs-u-p++@xl">
          <FontAwesomeIcon icon={faQuoteLeft} className="gs-u-mr+" />
          <p className="gs-u-display-inline">{text}</p>
        </div>
      </li>
    );
  };

  return (
    <React.Fragment>
      <ul className="reviews gel-layout gel-layout--equal">
        {reviews.map((review, index) =>
          renderReview(review, index, reviews.length > 1 ? "gel-1/2@l " : "")
        )}
      </ul>
    </React.Fragment>
  );
};

export default Reviews;
