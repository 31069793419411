import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// assets import
import "./assets/scss/app.scss";
import "font-awesome/css/font-awesome.min.css";
import "./assets/fonts/OPTIMA.TTF";
import "./assets/fonts/OPTIMA_B.TTF";
import AOS from "aos";
import "aos/dist/aos.css";

import { NavLink } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Modal from "./components/modal";
import Home from "./pages/home";
import FootClinic from "./pages/foot-clinic";
import InjuryClinic from "./pages/injury-clinic";
import Booking from "./pages/booking";
import ScrollToTop from "./components/scroll-to-top";

const NoMatch = () => (
  <div className="not-found gel-wrap gs-u-align-center">
    <h1 className="spacing-slice">404</h1>
    <h3 className="gs-u-mb+">Ooops!</h3>
    <p className="gs-u-mb">
      {"That page doesn't exist or is currently unavailable."}
    </p>
    <NavLink className="button gs-u-p+ spacing-slice--bottom" to="/">
      Take me back to the home page
    </NavLink>
  </div>
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.setIsModalOpen = this.setIsModalOpen.bind(this);
  }

  componentDidMount() {
    AOS.init({ once: true });
  }

  setIsModalOpen(isModalOpen) {
    this.setState({
      isModalOpen,
    });
  }

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <ScrollToTop />
        <>
          <Header setIsModalOpen={this.setIsModalOpen} />
          <main className="gs-u-box-size">
            <Switch>
              <Route
                exact
                path="/"
                component={() => (
                  <Home
                    isModalOpen={this.state.isModalOpen}
                    setIsModalOpen={this.setIsModalOpen}
                  />
                )}
              />
              <Route exact path="/foot-clinic" component={FootClinic} />
              <Route exact path="/foot-clinic/booking" component={Booking} />
              <Route exact path="/injury-clinic" component={InjuryClinic} />
              <Route exact path="/injury-clinic/booking" component={Booking} />
              <Route exact path="*" component={NoMatch} />
            </Switch>
          </main>
          <Footer />
          <Modal
            isModalOpen={this.state.isModalOpen}
            setIsModalOpen={this.setIsModalOpen}
          />
        </>
      </Router>
    );
  }
}

export default App;
