import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { pageView } from "../utils/analytics";
import bodyIcon from "../assets/img/body-icon.svg";
import footIcon from "../assets/img/foot-icon.svg";

/**
 * Home component
 *
 * @param {object} props
 */
const Home = ({ isModalOpen, setIsModalOpen }) => {
  useEffect(() => {
    document.title =
      "The Moffat Clinic - Helping you live your life pain free and to the best of your ability.";
  });

  useEffect(() => pageView());

  return (
    <React.Fragment>
      <section className="hero gs-o-media-island gs-u-align-center">
        <div className="hero__left"></div>
        <div className="gs-o-media-island">
          <ul className="hero__list gs-u-pb-alt++ gs-u-pb0@xl">
            <li>
              <div className="hero__list_item gs-u-mt++ gs-u-ph+ gs-u-ph0@xl">
                <div className="hero__heading gs-u-pv++ gs-u-pv0@xl">
                  <h2 className="gs-u-ph++ gs-u-ph0@xl gs-u-mb++">
                    If you have foot or leg pain, we have the solution
                  </h2>
                </div>
                <NavLink
                  className="gs-u-display-inline-block"
                  to="/foot-clinic"
                >
                  <figure className="gs-u-mb+">
                    <img src={footIcon} alt="" />
                  </figure>
                </NavLink>
                <div>
                  <h4 className="gs-u-mb+">The Foot Clinic</h4>
                  <NavLink
                    className="button button--large gs-u-p+ gs-u-mt"
                    to="/foot-clinic"
                  >
                    Find out more
                  </NavLink>
                </div>
              </div>
            </li>
            <li>
              <div className="hero__list_item gs-u-mt++">
                <div className="hero__versus">&</div>
              </div>
            </li>
            <li>
              <div className="hero__list_item gs-u-mt++ gs-u-ph0@xl">
                <div className="hero__heading gs-u-pv++ gs-u-pv0@xl">
                  <h2 className="gs-u-ph++ gs-u-ph0@xl gs-u-mb++">
                    If you have an injury or body pain, we can help
                  </h2>
                </div>
                <NavLink
                  className="gs-u-display-inline-block"
                  to="/injury-clinic"
                >
                  <figure className="gs-u-mb+">
                    <img src={bodyIcon} alt="" />
                  </figure>
                </NavLink>
                <div>
                  <h4 className="white gs-u-mb+">The Injury Clinic</h4>
                  <NavLink
                    className="button button--white button--large gs-u-p+ gs-u-mt"
                    to="/injury-clinic"
                  >
                    Find out more
                  </NavLink>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="testimonies spacing-slice gs-u-p++ gs-u-align-center">
        <div className="mini-wrap gs-u-pb+">
          <h2 className="mission-statement gs-u-mb++">
            <span className="gs-u-display-inline-block gs-u-mb++">
              Our Podiatrist & Sports Injury Rehabilitation Therapist want you
              to get moving & feeling better.
            </span>
            <span className="gs-u-display-inline-block gs-u-mb++">
              Whether it’s ingrown toe nails, knee pain or hard painful callous, to hip or upper body problems - Anne and Paula have the solution.
            </span>
            <span className="gs-u-display-inline-block gs-u-mb++">
              You can be sure The Moffat Clinic will get you back to better
              health and complete fitness - from head to toe.
            </span>
          </h2>
        </div>
        <button
          className="button nav__book_now nav__item gs-u-p+ gs-u-mb+"
          onClick={() => setIsModalOpen(true)}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="gs-u-mr@l" />
          <span>Book now</span>
        </button>
      </section>
    </React.Fragment>
  );
};

export default Home;
